
import React, { useState, useEffect } from 'react';
import { getFunctions, httpsCallable } from "firebase/functions";
import { Button, Dialog, DialogContent, Tooltip } from '@mui/material';

import { BsClipboard, BsPlay } from 'react-icons/bs';
import ReactPlayer from 'react-player/lazy'
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import {
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Paper,
    Pagination, IconButton
} from '@mui/material';
import CopyToClipboard from 'react-copy-to-clipboard';
const ReportedComments = ({ ...props }) => {
    const [comments, setComments] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [selectedComment, setSelectedComment] = useState(null);
    const functions = getFunctions();

    const pageSize = 10; // Number of comments per page  
    const [isOpen, setIsOpen] = useState(false);
    const [pageTokens, setPageTokens] = useState([null]);
    const [loading, setLoading] = useState(false);

    const handleOpenPopup = (comment) => {
        setSelectedComment(comment)
        setIsOpen(true);
    };

    const handleClosePopup = () => {
        setIsOpen(false);
    };
    const fetchComments = async (page) => {


        try {
            console.log('page', page);
            const callableReturnMessage = httpsCallable(functions, 'getPaginatedReportedComment');
            const pageToken = pageTokens[page - 1];
            console.log('page', { pageSize, pageToken });

            const response = await callableReturnMessage({ pageSize, pageToken });
            console.log('page', response);

            setComments(response.data.reportedComments);
            setTotalPages(Math.ceil(response.data.total / pageSize));
            // Update pageTokens state to keep track of page tokens for each page
            setPageTokens((prevTokens) => {
                const updatedTokens = [...prevTokens];
                updatedTokens[page] = response.data.nextPageToken;
                return updatedTokens;
            });
            setLoading(false);

        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    };

    useEffect(() => {
        fetchComments(1);
    }, []);
    const handlePageChange = (event, page) => {
        setCurrentPage(page);
        fetchComments(page);
    };

    const formatDate = (timestamp) => {
        if (!timestamp) {
            return "Invalid Date";
        }

        // Construct a JavaScript Date object using seconds and optional nanoseconds
        const date = new Date(timestamp._seconds * 1000); // Convert seconds to milliseconds

        // Format the date as needed
        const formattedDate = date.toLocaleString("en-US", {
            timeZone: "UTC", // Adjust timezone as per your requirement
            year: "numeric",
            month: "short",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            second: "numeric",
        });
        return formattedDate;

    };
    return (
        <div>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Comment Report ID</TableCell>

                            <TableCell>Comment Title</TableCell>
                            <TableCell>Comment ID</TableCell>
                            <TableCell>Post ID</TableCell>

                            <TableCell>Report Reason</TableCell>

                             <TableCell>Status  </TableCell>
                            <TableCell>Adjudication Status  </TableCell>

                            <TableCell>Adjudication User ID  </TableCell>
                            <TableCell>Adjudication Created Date  </TableCell>
                            <TableCell>Adjudication Reason  </TableCell>

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {comments?.map((comment) => (
                            <TableRow key={comment.commentReportId}>
                                <TableCell>  <CopyToClipboard text={comment.commentReportId}>
                                    <Button variant="text" startIcon={<BsClipboard />} style={{ padding: '0' }} >
                                        <Tooltip title="Comment Report ID" placement='right' arrow>
                                            <div>
                                                {comment.commentReportId.substring(0, 4)}...{comment.commentReportId.substring(comment.commentReportId.length - 3, comment.commentReportId.length)}
                                            </div>
                                        </Tooltip>
                                    </Button>
                                </CopyToClipboard>
                                </TableCell>
                                <TableCell>{comment.comment}</TableCell>
                                <TableCell>
                                    <CopyToClipboard text={comment.commentId}>
                                        <Button variant="text" startIcon={<BsClipboard />} style={{ padding: '0' }} >
                                            <Tooltip title="Comment ID" placement='right' arrow>
                                                <div>
                                                    {comment.commentId.substring(0, 4)}...{comment.commentId.substring(comment.commentId.length - 3, comment.commentId.length)}
                                                </div>
                                            </Tooltip>
                                        </Button>
                                    </CopyToClipboard>
                                </TableCell>
                                <TableCell>
                                    <CopyToClipboard text={comment.postId}>
                                        <Button variant="text" startIcon={<BsClipboard />} style={{ padding: '0' }} >
                                            <Tooltip title="Post ID" placement='right' arrow>
                                                <div>
                                                    {comment.postId.substring(0, 4)}...{comment.postId.substring(comment.postId.length - 3, comment.postId.length)}
                                                </div>
                                            </Tooltip>
                                        </Button>
                                    </CopyToClipboard>
                                </TableCell>
                                <TableCell>{comment.reportReasonDescription}</TableCell>
 

                                <TableCell>
                                    {comment.status}
                                </TableCell>
                                <TableCell>
                                    {comment.adjudicationstatus}
                                </TableCell>


                                <TableCell>
                                    {comment.adjudicatedByUserId ? <CopyToClipboard text={comment.adjudicatedByUserId}>
                                        <Button variant="text" startIcon={<BsClipboard />} style={{ padding: '0' }} >
                                            <Tooltip title=" UserId ID" placement='right' arrow>
                                                <div>
                                                    {comment.adjudicatedByUserId?.substring(0, 4)}...{comment.adjudicatedByUserId?.substring(comment.adjudicatedByUserId?.length - 3, comment.adjudicatedByUserId?.length)}
                                                </div>
                                            </Tooltip>
                                        </Button>
                                    </CopyToClipboard> : "N/A"}
                                </TableCell>

                                <TableCell>
                                    {comment.adjudicationDate ? formatDate(comment.adjudicationDate) : "N/A"}

                                </TableCell>

                                <TableCell>
                                    {comment.adjudicationReason}
                                </TableCell>

                          

                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <div className="pagination">
                <Pagination
                    count={totalPages}
                    page={currentPage}
                    onChange={handlePageChange}
                    variant="outlined" shape="rounded"
                    color="primary" />
            </div>
        </div>
    );
};
export default ReportedComments
