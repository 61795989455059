
import { makeStyles } from 'tss-react/mui';

import React, { useState, useEffect, useRef } from 'react';
import { getFunctions, httpsCallable } from "firebase/functions";

import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";

import {
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Paper,
    Pagination,
    IconButton,
    MenuItem,
    Dialog,
    DialogTitle,
    ListItem,
    ListItemText,
    Typography,
    List,
    DialogContent,
    Divider,
    CircularProgress,
    Tooltip,
    Button,
    InputLabel,
    FormControl,
    Select,
    Grid,
    TextField
} from '@mui/material';

import EditIcon from '@mui/icons-material/Edit';
import Loader from '../../../ui-elements/loader';
import { Countertops, History, Refresh } from '@mui/icons-material';
import CopyToClipboard from 'react-copy-to-clipboard';
import { BsClipboard, BsSearch } from 'react-icons/bs';
import { toast } from 'react-toastify';
import { Field, Form, Formik } from 'formik';
const Users = ({ ...props }) => {
    const [users, setUsers] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const { classes } = useStyles();
    const [pageTokens, setPageTokens] = useState([null]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [isOpenMenu, setIsOpenMenu] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isPaymentloading, setIsPaymentloading] = useState(false);

    const [isPaymentsHistoryOpen, setIsPaymentsHistoryOpen] = useState(false);
    const [paymentsHistory, setPaymentsHistory] = useState([]);
    const pageSize = 10;
    const functions = getFunctions();

    const [showFilters, setShowFilters] = useState(false);

    const [reportedUsersCount, setReportedUsersCount] = useState(0);
    const [usersCount, setUsersCount] = useState(0);
    const formRef = useRef();
    const [searchValues, setSearchValues] = useState({});

    const getAllCount = async () => {
        const functions = getFunctions();

        const callableReturnMessage = httpsCallable(functions, 'getAllCounts');

        callableReturnMessage().then((result) => {
            setUsersCount(result.data.userCount);
            setReportedUsersCount(result.data.userReportsCount);
        }).catch((error) => {
            console.log(error);
        });

    };

    useEffect(() => {
        getAllCount();
    }, []);
    const toggleFilters = () => {
        setShowFilters(prev => !prev);
    };
    const handleClosePopup = () => {
        setIsOpenMenu(false);
        setIsPaymentsHistoryOpen(false);
    };
    const fetchUsers = async (page) => {
        try {
            setCurrentPage(page);
            setLoading(true);

            const callableReturnMessage = httpsCallable(functions, 'getPaginatedUsers');
            const pageToken = pageTokens[page - 1]; // Adjust index to match page number
            const response = await callableReturnMessage({ pageSize, pageToken });
            setUsers(response.data.users);
            setTotalPages(Math.ceil(response.data.totalUsers / pageSize));
            // Update pageTokens state to keep track of page tokens for each page
            setPageTokens((prevTokens) => {
                const updatedTokens = [...prevTokens];
                updatedTokens[page] = response.data.nextPageToken;
                return updatedTokens;
            });
            setLoading(false);

        } catch (error) {
            console.log(error);
            setLoading(false);

        }
    };


    useEffect(() => {
        setLoading(true);

        fetchUsers(1); // Fetch initial page
    }, []);


    const handlePageChange = (event, page) => {

        // Check if search form is not empty
        console.log('formRef', formRef)
        if (formRef && formRef.current && (formRef.current.values.userId != "" ||
            formRef.current.values.postId != "" ||
            formRef.current.values.userDisplayName != "" ||
            formRef.current.values.postTitle != "" ||
            formRef.current.values.status != "")

        ) {
            searchBy(searchValues, page);
        } else {
            console.log('fetchPosts', page)

            // Handle pagination without search
            fetchUsers(page);
        }
    };
    const getStatusClass = (status) => {
        switch (status) {
            case 'disabled':
                return classes.disabled;
            case 'active':
                return classes.active;
            case 'deleted':
                return classes.deleted;
            default:
                return '';
        }
    };
    const handleClick = (event, user) => {
        setSelectedUser(user);
        setIsOpenMenu(true);

    };
    const handleClose = () => {
        setSelectedUser(null);
    };
    const handleStatusChange = (selectedUser, status) => {
        selectedUser.status = status;

        const callableReturnMessage = httpsCallable(functions, 'changeUserStatus');

        callableReturnMessage({ userId: selectedUser.userId, status: status }).then((response) => {

            toast.success('Status was changed successfully.');
            selectedUser.status = status;

        }).catch((error) => {
            console.log(error);
            toast.success("Couldn/'t change status.");

        });
    }
    const handleStatusMenuItemClick = (status) => {
        handleStatusChange(selectedUser, status);
        handleClose();
        handleClosePopup();
    };
    const fetchPaymentHistory = async (user) => {
        try {
            console.log('userId,', user.userId);
            setPaymentsHistory([]);
            setIsPaymentloading(true)
            const callableGetPayments = httpsCallable(functions, 'getUserPaymentHistoryByUserId');
            const response = await callableGetPayments({ userId: user.userId });
            const paymentsHistory = response.data.paymentsHistory || [];
            console.log('paymentsHistory', paymentsHistory);
            setPaymentsHistory([...paymentsHistory]);
            setIsPaymentloading(false)

        } catch (error) {
            console.log(error);
            setIsPaymentloading(false)

        }
    };
    const handleOpenPaymentHistoryPopup = async (user) => {
        console.log('userxx', user.userId)

        setIsPaymentsHistoryOpen(true);
        setPaymentsHistory([]);

        fetchPaymentHistory(user);
    };
    const formatDate = (timestamp) => {
        if (!timestamp) {
            return "Invalid Date";
        }

        // Construct a JavaScript Date object using seconds and optional nanoseconds
        const date = new Date(timestamp._seconds * 1000); // Convert seconds to milliseconds

        // Format the date as needed
        const formattedDate = date.toLocaleString("en-US", {
            timeZone: "UTC", // Adjust timezone as per your requirement
            year: "numeric",
            month: "short",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            second: "numeric",
        });
        return formattedDate;

    };
    const searchBy = (values, page) => {
        console.log('searchPosts', values)
        setCurrentPage(page);

        setLoading(true);

        const searchUsers = httpsCallable(functions, 'searchUsers');
        const pageToken = pageTokens[page - 1];

        searchUsers({

            userId: values.userId,
            userDisplayName: values.userDisplayName,
            username: values.username,
            sortOrder: values.orderby,
            status: values.status,
            pageSize: 10,
            pageToken: pageToken
        }).then((response) => {
            console.log('response', response.data);
            setUsers(response.data.users);
            setTotalPages(Math.ceil(response.data.total / pageSize));
            // Update pageTokens state to keep track of page tokens for each page
            setPageTokens((prevTokens) => {
                const updatedTokens = [...prevTokens];
                updatedTokens[page] = response.data.nextPageToken;
                return updatedTokens;
            });
            setLoading(false);

            console.log('response.date', response.data)

        }).catch((error) => {
            console.error("Error searching posts:", error);
        });

    }
    return (
        <div>   <Grid container style={{ marginBottom: '20px', backgroundColor: 'white', padding: "20px" }} >
            <Grid item xs={12} sm={12} md={2}>
                <Typography color="primary" variant="h4">Users Counts : {usersCount}</Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
                <Typography color="primary" variant="h4" >Reported Users Counts : {reportedUsersCount}</Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
                <Button variant="outlined" onClick={toggleFilters} style={{ display: 'flex', marginLeft: 'auto' }}>
                    {showFilters ? 'Hide Filters' : 'Show Filters'}
                </Button>

            </Grid>
            <Grid item xs={12} sm={12} md={12}>


                {showFilters && (<Formik
                    innerRef={formRef}

                    initialValues={{
                        userId: "",
                        postId: "",
                        userDisplayName: "",
                        Username: "",
                        orderby: "desc",
                        status: ""
                    }}
                    onSubmit={async (
                        values,
                    ) => {
                        searchBy(values, 1);

                    }}
                >
                    {({ handleSubmit, handleBlur, values }) => (

                        <Form onSubmit={handleSubmit} >
                            <Grid container style={{ marginBottom: '5px', marginTop: '5px', }} >

                                <Grid item xs={12} sm={12} md={3} margin={2}>
                                    <Field
                                        name="userId"
                                        as={TextField}
                                        label="User ID"
                                        variant="outlined"
                                        onBlur={handleBlur}
                                        value={values.userId}

                                        fullWidth
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} md={3} margin={2}>
                                    <Field
                                        name="userDisplayName"
                                        as={TextField}
                                        label="User Display Name"
                                        variant="outlined"
                                        onBlur={handleBlur}
                                        value={values.userDisplayName}

                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={3} margin={2}>
                                    <Field
                                        name="username"
                                        as={TextField}
                                        label="Username"
                                        variant="outlined"
                                        onBlur={handleBlur}
                                        value={values.username}

                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={3} margin={2}>
                                    <FormControl fullWidth variant="outlined">
                                        <InputLabel id="sort-order-label">Sort Order</InputLabel>
                                        <Field
                                            name="orderby"
                                            as={Select}
                                            labelId="sort-order-label"
                                            label="Sort Order"
                                            variant="outlined"
                                            value={values.orderby}

                                        >
                                            <MenuItem value="asc">Ascending</MenuItem>
                                            <MenuItem value="desc">Descending</MenuItem>
                                        </Field>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12} md={3} margin={2}>
                                    <FormControl fullWidth variant="outlined">
                                        <InputLabel id="status-order-label">Status</InputLabel>
                                        <Field
                                            name="status"
                                            as={Select}
                                            labelId="status-order-label"
                                            label="Status"
                                            variant="outlined"
                                            value={values.status}

                                        >
                                            <MenuItem value="active">Active</MenuItem>
                                            <MenuItem value="deleted">Deleted</MenuItem>
                                            <MenuItem value="disabled">Disabled</MenuItem>

                                        </Field>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12} md={3} margin={2}>
                                    <Button type="submit" variant="contained" color="primary"  >
                                        <BsSearch />
                                    </Button>
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>)} </Grid>
        </Grid>

            {loading ? <Loader /> : <div>

                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>User ID</TableCell>

                                <TableCell>Display Name</TableCell>
                                <TableCell>User Name</TableCell>
                                <TableCell>Email</TableCell>
                                <TableCell>Picture</TableCell>
                                <TableCell>Balance</TableCell>

                                <TableCell>Payment History</TableCell>

                                <TableCell>Status</TableCell>
                                <TableCell>Created Date</TableCell>

                       
                                <TableCell>
                                    <IconButton
                                        aria-label="see"
                                        aria-controls="long-menu"
                                        aria-haspopup="true"
                                        onClick={(e) => fetchUsers(1)}
                                    >
                                        <Refresh />
                                    </IconButton>
                                </TableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {users?.map((user) => (
                                <TableRow key={user.userId}>
                                    <TableCell>

                                        <CopyToClipboard text={user.userId}>
                                            <Button variant="text" startIcon={<BsClipboard />} style={{ padding: '0' }} >
                                                <Tooltip title="User ID" placement='right' arrow>
                                                    <div>
                                                        {user.userId.substring(0, 4)}...{user.userId.substring(user.userId.length - 3, user.userId.length)}
                                                    </div>
                                                </Tooltip>
                                            </Button>
                                        </CopyToClipboard>
                                    </TableCell>

                                    <TableCell>{user.displayName}</TableCell>

                                    <TableCell>{user.username}</TableCell>
                                    <TableCell>{user.email}</TableCell>
                                    <TableCell  >
                                        <Zoom>
                                            {user.photoUrl && <img src={user.photoUrl} width={30} height={30} alt="User Photo" style={{ cursor: 'zoom-in', borderRadius: '50%', marginTop: '10px' }} />}
                                        </Zoom>
                                    </TableCell>
                                    <TableCell>{user.balance}</TableCell>

                                    <TableCell>
                                        <IconButton
                                            variant="outlined"
                                            color='primary'
                                            onClick={() => {
                                                setSelectedUser(user);
                                                setPaymentsHistory([]);

                                                console.log('userx', user.userId);
                                                handleOpenPaymentHistoryPopup(user)
                                            }}
                                        >
                                            <History />
                                        </IconButton>
                                    </TableCell>
                                    <TableCell className={getStatusClass(user.status)}>

                                        {user.status.charAt(0).toUpperCase() + user.status.slice(1)}

                                    </TableCell>
                                    <TableCell>
                                        {formatDate(user.createdAt)}
                                    </TableCell>
                                    <TableCell>
                                        <IconButton
                                            aria-label="more"
                                            aria-controls="long-menu"
                                            aria-haspopup="true"
                                            onClick={(e) => handleClick(e, user)}
                                        >
                                            <EditIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <div className="pagination">
                    <Pagination
                        count={totalPages}
                        page={currentPage}
                        onChange={handlePageChange}
                        variant="outlined"
                        shape="rounded"
                        color='primary'
                    />
                </div>
                <Dialog BackdropProps={{ style: { backgroundColor: 'rgba(128, 128, 128, 0.5)' } }} open={isOpenMenu} onClose={handleClosePopup}>
                    <DialogTitle>Change User Status</DialogTitle>


                    <MenuItem className={classes.barMenuItem} onClick={() => handleStatusMenuItemClick('active')}>Active</MenuItem>
                    <MenuItem className={classes.barMenuItem} onClick={() => handleStatusMenuItemClick('reported')}>Reported</MenuItem>
                    <MenuItem className={classes.barMenuItem} onClick={() => handleStatusMenuItemClick('disabled')}>Disabled</MenuItem>

                    <MenuItem className={classes.barMenuItem} onClick={() => handleStatusMenuItemClick('deleted')}>Deleted</MenuItem>
                </Dialog>
                <Dialog open={isPaymentsHistoryOpen} onClose={handleClosePopup}>
                    <DialogContent style={{ minWidth: '500px', overflowX: 'auto' }}>
                        <Typography variant="h5" align='center'>List of Payments History :</Typography>
                        <Typography>{selectedUser?.username}</Typography>

                        {isPaymentloading ? <CircularProgress size={24} color="warning" /> : <List style={{ maxHeight: '300px', overflowY: 'auto' }}>
                            {paymentsHistory.map((payment) => (
                                <div key={payment.createdAt}>
                                    <ListItemText primary={payment.productId} secondary={` ${payment.credits}`} />
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <Typography variant="body2" className={getStatusClass(payment.status)}>
                                            {payment.status.charAt(0).toUpperCase() + payment.status.slice(1)}
                                        </Typography>

                                    </div>

                                    <Typography variant="body2" >
                                        {payment.platform}
                                    </Typography>
                                    <Typography variant="body2" >
                                        {formatDate(payment.createdAt)}
                                    </Typography>
                                    <Divider style={{ marginBottom: '20px', marginTop: '20px' }} />

                                </div>
                            ))}
                            {paymentsHistory.length == 0 && (
                                <Typography>No content yet.</Typography>
                            )}
                        </List>}
                    </DialogContent>
                </Dialog>

            </div>}

        </div>
    );
};
const useStyles = makeStyles({ "name": { Users } })(theme => ({
    formInput: {
        ...theme.typography.customInput,
        marginBottom: '10px !important'
    },
    disabled: {
        color: theme.palette.error.main, // Customize the color for disabled status
    },
    active: {
        color: theme.palette.primary.main, // Customize the color for active status
    },
    deleted: {
        color: theme.palette.error.main, // Customize the color for deleted status
    },
    reported: {
        color: theme.palette.error.main, // Customize the color for deleted status
    },
    avatar: {
        margin: 'auto',

        width: '100px!important',
        height: '100px!important'

    },
    dialogHeader: {
        paddingBottom: '50px!important'
    }, barMenuItem: {
        width: '200px',
        textAlign: 'center',
        padding: '20px!important',
        "&:hover": {
            background: theme.palette.success.main
        }
    },

}));


export default Users
