
import { makeStyles } from 'tss-react/mui';

import React, { useState, useEffect } from 'react';
import { getFunctions, httpsCallable } from "firebase/functions";

import {
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Paper,
    Pagination,
} from '@mui/material';
const ReportedUsers = ({ ...props }) => {
    const [users, setUsers] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const functions = getFunctions();

    const { classes } = useStyles();
    const [pageTokens, setPageTokens] = useState([null]);
    const pageSize = 10; // Number of posts per page
    const fetchReportedUsers = async (page) => {
        try {

            const callableReturnMessage = httpsCallable(functions, 'getPaginatedReportedUsers');
            const pageToken = pageTokens[page - 1]; // Adjust index to match page number
            const response = await callableReturnMessage({ pageSize, pageToken });
            setUsers(response.data.reportedUsers);
            setTotalPages(Math.ceil(response.data.totalUsers / pageSize));
            setPageTokens((prevTokens) => {
                const updatedTokens = [...prevTokens];
                updatedTokens[page] = response.data.nextPageToken;
                return updatedTokens;
            });
         } catch (error) {
            console.error('Error fetching users:', error);
        }
    };

    useEffect(() => {
        fetchReportedUsers(1);
    }, []);

    const handlePageChange = (event, page) => {
        setCurrentPage(page);
        fetchReportedUsers(page);
    };
    return (
        <div>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>User Name</TableCell>
 
                            <TableCell>Reason  </TableCell>

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {users?.map((user) => (
                            <TableRow key={user.id}>
                                <TableCell>{user.username}</TableCell>
 
                                <TableCell>{user.reportReasonDescription}</TableCell>

                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <div className="pagination">
                <Pagination
                    count={totalPages}
                    page={currentPage}
                    onChange={handlePageChange}
                    variant="outlined"
                    shape="rounded"
                    color='primary'
                />
            </div>
        </div>
    );
};

const useStyles = makeStyles({ "name": { ReportedUsers } })(theme => ({
    formInput: {
        ...theme.typography.customInput,
        marginBottom: '10px !important'
    },
    disabled: {
        color: theme.palette.error.main, // Customize the color for disabled status
    },
    active: {
        color: theme.palette.primary.main, // Customize the color for active status
    },
    deleted: {
        color: theme.palette.warning.main, // Customize the color for deleted status
    },
    reported: {
        color: theme.palette.warning.main, // Customize the color for deleted status
    },
    avatar: {
        margin: 'auto',

        width: '100px!important',
        height: '100px!important'

    },
    dialogHeader: {
        paddingBottom: '50px!important'
    }, barMenuItem: {
        width: '200px',
        textAlign: 'center',
        padding: '20px!important',
        "&:hover": {
            background: theme.palette.success.main
        }
    },

}));

export default ReportedUsers
