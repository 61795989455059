import React, { useState, useEffect, useRef } from 'react';
import { getFunctions, httpsCallable } from 'firebase/functions';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import {
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Paper,
    MenuItem,
    IconButton,
    Dialog,
    DialogContent,
    Pagination,
    DialogTitle,
    Typography,
    CircularProgress,
    List,
    ListItem,
    ListItemText,
    ListItemAvatar,
    Avatar,
    Menu,
    Grid,
    Modal,
    Tooltip,
    Button,
    Box,
    Container, TextField,
    InputLabel,
    FormControl,
    Select,
    FormControlLabel,
    Radio,
    RadioGroup
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import Loader from '../../../ui-elements/loader';
import ChatIcon from '@mui/icons-material/Chat';
import EditIcon from '@mui/icons-material/Edit';
import CopyToClipboard from 'react-copy-to-clipboard';
import { BsClipboard, BsSearch } from 'react-icons/bs';
import { toast } from 'react-toastify';
import { Field, Form, Formik } from 'formik';
import { Check, Loop, Refresh } from '@mui/icons-material';

const Posts = () => {

    const [posts, setPosts] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const { classes } = useStyles();
    const [isOpen, setIsOpen] = useState(false);
    const [selectedPost, setSelectedPost] = useState(null);
    const [selectedPropertyType, setSelectedPropertyType] = useState(null);
    const [searchValues, setSearchValues] = useState({});
    const formRef = useRef();

    const [isOpenMenu, setIsOpenMenu] = useState(false);
    const [isCommentOpen, setIsCommentOpen] = useState(false);
    const [comments, setComments] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const commentsContainerRef = useRef(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedComment, setSelectedComment] = useState(null);

    const functions = getFunctions();
    const [loading, setLoading] = useState(false);

    const pageSize = 10; // Number of posts per page
    const [pageTokens, setPageTokens] = useState([null]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const MAX_LENGTH = 30;

    const [reportedPostsCount, setReportedPostsCount] = useState(0);
    const [postsCount, setPostsCount] = useState(0);
    const [adjudicationReason, setadjudicationReason] = useState("");


    const getAllCount = async () => {
        const functions = getFunctions();

        const callableReturnMessage = httpsCallable(functions, 'getAllCounts');

        callableReturnMessage().then((result) => {
            setPostsCount(result.data.postsCount);
            setReportedPostsCount(result.data.postReportsCount);
        }).catch((error) => {
            console.log(error);
        });

    };

    useEffect(() => {
        getAllCount();
    }, []);
    const handleOpenModal = (post, type) => {
        setSelectedPost(post);
        setSelectedPropertyType(type);
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };
    useEffect(() => {
        const container = commentsContainerRef.current;
        if (container) {
            container.addEventListener('scroll', handleScroll);
            return () => {
                container.removeEventListener('scroll', handleScroll);
            };
        }
    }, [commentsContainerRef]);

    const handleScroll = () => {
        const container = commentsContainerRef.current;
        if (container.scrollHeight - container.scrollTop === container.clientHeight && hasMore) {
            if (comments.length > pageSize) {
                console.log('setHasMore')
                setHasMore(true);
            } else {
                setHasMore(false);
            }

            fetchMoreComments();
        }
    };

    const handleMenuOpen = (event, comment) => {
        setSelectedComment(comment);

        setAnchorEl(event.currentTarget);

    };

    const handleMenuClose = () => {

        setAnchorEl(null);
        setSelectedComment(null);
    };
    const fetchPosts = async (page) => {
        try {
            setCurrentPage(page);
            setLoading(true);

            const callableReturnMessage = httpsCallable(functions, 'getPaginatedPosts');
            const pageToken = pageTokens[page - 1];
            const response = await callableReturnMessage({ pageSize, pageToken });
            console.log('response.data', response.data);
            setPosts(response.data.posts);
            setTotalPages(Math.ceil(response.data.total / pageSize));
            // Update pageTokens state to keep track of page tokens for each page
            setPageTokens((prevTokens) => {
                const updatedTokens = [...prevTokens];
                updatedTokens[page] = response.data.nextPageToken;
                return updatedTokens;
            });
            setLoading(false);

        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    };
    useEffect(() => {
        setLoading(true);

        fetchPosts(1);
    }, []);



    const handleClosePopup = () => {
        setIsOpen(false);
        setIsCommentOpen(false);
        setIsOpenMenu(false);
    };
    const getStatusClass = (status) => {
        switch (status) {
            case 'disabled':
                return classes.disabled;
            case 'active':
                return classes.active;
            case 'completed':
                return classes.active;
            case 'deleted':
                return classes.deleted;
            case 'failed':
                return classes.deleted;
            case 'reported':
                return classes.reported;
            default:
                return '';
        }
    };
    const handleClick = (event, post) => {
        setSelectedPost(post);

        setIsOpenMenu(true);
    };

    const handleClose = () => {
        setSelectedPost(null);
        setIsOpenMenu(false);
        setIsOpen(false);


    };
    const searchBy = (values, page) => {
        console.log('searchPosts', values)
        setCurrentPage(page);

        setLoading(true);

        const searchPosts = httpsCallable(functions, 'searchPosts');
        const pageToken = pageTokens[page - 1];

        searchPosts({

            userId: values.userId,
            postId: values.postId,
            userDisplayName: values.userDisplayName,
            postTitle: values.postTitle,
            sortOrder: values.orderby,
            status: values.status,
            pageSize: 10,
            pageToken: pageToken
        }).then((response) => {
            console.log('response', response.data);
            setPosts(response.data.posts);
            setTotalPages(Math.ceil(response.data.total / pageSize));
            // Update pageTokens state to keep track of page tokens for each page
            setPageTokens((prevTokens) => {
                const updatedTokens = [...prevTokens];
                updatedTokens[page] = response.data.nextPageToken;
                return updatedTokens;
            });
            setLoading(false);

            console.log('response.date', response.data)

        }).catch((error) => {
            console.error("Error searching posts:", error);
        });

    }
    const handlePageChange = (event, page) => {

        // Check if search form is not empty
        console.log('formRef', formRef)
        if (formRef && formRef.current && (formRef.current.values.userId != "" ||
            formRef.current.values.postId != "" ||
            formRef.current.values.userDisplayName != "" ||
            formRef.current.values.postTitle != "" ||
            formRef.current.values.status != "")

        ) {
            searchBy(searchValues, page);
        } else {
            console.log('fetchPosts', page)

            // Handle pagination without search
            fetchPosts(page);
        }
    };
    const handleStatusChange = (values) => {

        const callableReturnMessage = httpsCallable(functions, 'changePostStatus');

        callableReturnMessage({ postId: selectedPost.postId, status: values.status, adjudicationReason: values.adjudicationReason }).then(() => {
            toast.success('Status was changed successfully.');
            selectedPost.status = values.status;

        }).catch((error) => {
            console.log(error);
            toast.success("Couldn/'t change status.");

        });
    }
    const handleStatusMenuItemClick = (values) => {
        console.log('status', values)
        handleStatusChange(values);
        handleClose();
    };
    const handleCommentStatusMenuItemClick = (values) => {
        console.log('status', values)
        handleCommentStatusChange(values);
        handleMenuClose();
        handleClosePopup();

    };

    const fetchComments = async (postId, startAfter) => {
        try {
            const callableGetComments = httpsCallable(functions, 'getAdminCommentsByPost');
            const response = await callableGetComments({ postId, limit: pageSize, startAfter });
            const newComments = response.data.comments || [];
            console.log('newComments', response);
            setComments((prevComments) => [...prevComments, ...newComments]);
            if (comments.length > pageSize) {
                console.log('setHasMore')
                setHasMore(true);
            } else {
                setHasMore(false);
            }

        } catch (error) {
            console.log(error);
            setHasMore(false);

        }
    };

    const fetchMoreComments = () => {
        const lastComment = comments[comments.length - 1];
        fetchComments(selectedPost, lastComment);
    };
    const handleOpenCommentPopup = async (postId) => {
        setIsCommentOpen(true);
        setSelectedPost(postId);
        setComments([]);
        fetchComments(postId, null);
    };
    const handleCommentStatusChange = (values) => {
        console.log('selectedComment', selectedComment);
        const callableReturnMessage = httpsCallable(functions, 'changeCommentStatusByAdmin');
        selectedComment.status = values.status;

        callableReturnMessage({ commentId: selectedComment.commentId, postId: selectedComment.postId, status: values.status, adjudicationReason: values.adjudicationReason }).then(() => {
            toast.success('Status was changed successfully.');

        }).catch((error) => {
            console.log(error);
            toast.success("Couldn/'t change status.");

        });
    }
    const handleEditStatus = (comment, status) => {
        comment.status = status;
        handleCommentStatusChange(comment, status)
        handleMenuClose();
    }
    const renderContent = (post) => {
        if (post?.prompt?.length > MAX_LENGTH) {
            return (
                <>
                    {post.prompt.substring(0, MAX_LENGTH)}...
                    <span onClick={() => handleOpenModal(post, 'prompt')} style={{ color: 'blue', cursor: 'pointer' }}> 🔍</span >
                </>
            );
        }
        return post?.prompt;
    };
    const renderLogContent = (post) => {
        if (post?.failurelog?.length > MAX_LENGTH) {
            return (
                <>
                    {post.failurelog.substring(0, 20)}...
                    <span onClick={() => handleOpenModal(post, 'failure log')} style={{ color: 'blue', cursor: 'pointer' }}> 🔍</span >
                </>
            );
        }
        return post?.failurelog;
    };
    const formatDate = (timestamp) => {
        if (!timestamp) {
            return "Invalid Date";
        }

        // Construct a JavaScript Date object using seconds and optional nanoseconds
        const date = new Date(timestamp._seconds * 1000); // Convert seconds to milliseconds

        // Format the date as needed
        const formattedDate = date.toLocaleString("en-US", {
            timeZone: "UTC", // Adjust timezone as per your requirement
            year: "numeric",
            month: "short",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            second: "numeric",
        });
        return formattedDate;

    };
    const renderTitleContent = (post) => {
        if (post?.title?.length > MAX_LENGTH) {
            return (
                <>
                    {post.title.substring(0, 20)}...
                    <span onClick={() => handleOpenModal(post, 'title')} style={{ color: 'blue', cursor: 'pointer' }}> 🔍</span >
                </>
            );
        }
        return post?.title;
    };
    const [showFilters, setShowFilters] = useState(false);

    const toggleFilters = () => {
        setShowFilters(prev => !prev);
    };
    return (
        <div>     <Grid container style={{ marginBottom: '20px', backgroundColor: 'white', padding: "20px" }} >
            <Grid item xs={12} sm={12} md={2}>
                <Typography color="primary" variant="h4">Posts Counts : {postsCount}</Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
                <Typography color="primary" variant="h4" >Reported Posts Counts : {reportedPostsCount}</Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
                <Button variant="outlined" onClick={toggleFilters} style={{ display: 'flex', marginLeft: 'auto' }}>
                    {showFilters ? 'Hide Filters' : 'Show Filters'}
                </Button>

            </Grid>
            <Grid item xs={12} sm={12} md={12}>


                {showFilters && (<Formik
                    innerRef={formRef}

                    initialValues={{
                        userId: "",
                        postId: "",
                        userDisplayName: "",
                        postTitle: "",
                        orderby: "desc",
                        status: ""
                    }}
                    onSubmit={async (
                        values,
                    ) => {
                        searchBy(values, 1);

                    }}
                >
                    {({ handleSubmit, handleBlur, values }) => (

                        <Form onSubmit={handleSubmit} >
                            <Grid container style={{ marginBottom: '5px', marginTop: '5px', }} >

                                <Grid item xs={12} sm={12} md={3} margin={2}>
                                    <Field
                                        name="userId"
                                        as={TextField}
                                        label="User ID"
                                        variant="outlined"
                                        onBlur={handleBlur}
                                        value={values.userId}

                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={3} margin={2}>
                                    <Field
                                        name="postId"
                                        as={TextField}
                                        label="Post ID"
                                        variant="outlined"
                                        onBlur={handleBlur}
                                        value={values.postId}

                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={3} margin={2}>
                                    <Field
                                        name="userDisplayName"
                                        as={TextField}
                                        label="User Display Name"
                                        variant="outlined"
                                        onBlur={handleBlur}
                                        value={values.userDisplayName}

                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={3} margin={2}>
                                    <Field
                                        name="postTitle"
                                        as={TextField}
                                        label="Post Title"
                                        variant="outlined"
                                        onBlur={handleBlur}
                                        value={values.postTitle}

                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={3} margin={2}>
                                    <FormControl fullWidth variant="outlined">
                                        <InputLabel id="sort-order-label">Sort Order</InputLabel>
                                        <Field
                                            name="orderby"
                                            as={Select}
                                            labelId="sort-order-label"
                                            label="Sort Order"
                                            variant="outlined"
                                            value={values.orderby}

                                        >
                                            <MenuItem value="asc">Ascending</MenuItem>
                                            <MenuItem value="desc">Descending</MenuItem>
                                        </Field>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12} md={3} margin={2}>
                                    <FormControl fullWidth variant="outlined">
                                        <InputLabel id="status-order-label">Status</InputLabel>
                                        <Field
                                            name="status"
                                            as={Select}
                                            labelId="status-order-label"
                                            label="Status"
                                            variant="outlined"
                                            value={values.status}

                                        >
                                            <MenuItem value="completed">Completed</MenuItem>
                                            <MenuItem value="reported">Reported</MenuItem>
                                            <MenuItem value="deleted">Deleted</MenuItem>
                                            <MenuItem value="banned">Banned</MenuItem>

                                        </Field>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12} md={3} margin={2}>
                                    <Button type="submit" variant="contained" color="primary"  >
                                        <BsSearch />
                                    </Button>
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>)} </Grid>
        </Grid>


            {loading ? <Loader /> : <div>

                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>ID</TableCell>

                                <TableCell>Title</TableCell>
                                <TableCell>Prompt</TableCell>
                                <TableCell>Media</TableCell>
                                <TableCell>User</TableCell>
                                <TableCell>Credit Cost</TableCell>
                                <TableCell>Availability</TableCell>
                                <TableCell>Platform</TableCell>


                                <TableCell>Status</TableCell>
                                <TableCell>Failure Log</TableCell>
                                <TableCell>Created Date</TableCell>

                                <TableCell> </TableCell>

                                <TableCell>
                                    <IconButton
                                        aria-label="see"
                                        aria-controls="long-menu"
                                        aria-haspopup="true"
                                        onClick={(e) => fetchPosts(1)}
                                    >
                                        <Refresh />
                                    </IconButton>
                                </TableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>

                            {posts.map((post) => (
                                <TableRow key={post.postId}>
                                    <TableCell>

                                        <CopyToClipboard text={post.postId}>
                                            <Button variant="text" startIcon={<BsClipboard />} style={{ padding: '0' }} >
                                                <Tooltip title="Post ID" placement='right' arrow>
                                                    <div>
                                                        {post.postId.substring(0, 4)}...{post.postId.substring(post.postId.length - 3, post.postId.length)}
                                                    </div>
                                                </Tooltip>
                                            </Button>
                                        </CopyToClipboard>
                                    </TableCell>
                                    <TableCell>{renderTitleContent(post)}</TableCell>
                                    <TableCell>{renderContent(post)}</TableCell>
                                    <TableCell>
                                        <Zoom>
                                            {post.mediaUrl && <img src={post.mediaUrl} width={30} height={30} alt="mediaUrl" style={{ cursor: 'zoom-in', borderRadius: '50%', marginTop: '10px' }} />}
                                        </Zoom>
                                    </TableCell>
                                    <TableCell>
                                        <Zoom>
                                            {post.avatarUrl && (
                                                <img src={post.avatarUrl} width={30} height={30} alt="user" style={{ cursor: 'zoom-in', borderRadius: '50%', marginTop: '10px' }} />
                                            )}
                                        </Zoom>
                                        <div>
                                            {post.displayName}</div>
                                        <CopyToClipboard text={post.userId}>
                                            <Button variant="text" startIcon={<BsClipboard />} style={{ padding: '0' }} >
                                                <Tooltip title="User ID" placement='right' arrow>
                                                    <div>
                                                        {post.userId.substring(0, 2)}...{post.userId.substring(post.userId.length - 1, post.userId.length)}
                                                    </div>
                                                </Tooltip>
                                            </Button>
                                        </CopyToClipboard>
                                    </TableCell>
                                    <TableCell  >
                                        {post.creditsConsumed}
                                    </TableCell>
                                    <TableCell  >
                                        {post.isPublic ? 'Public' : 'Private'}
                                    </TableCell>
                                    <TableCell  >
                                        {post.platform}
                                    </TableCell>
                                    <TableCell className={getStatusClass(post.status)}>
                                        {post.status.charAt(0).toUpperCase() + post.status.slice(1)} </TableCell>

                                    <TableCell  >
                                        {post.failurelog != "" ? renderLogContent(post) : "N/A"}
                                    </TableCell>
                                    <TableCell>
                                        {formatDate(post.createdAt)}
                                    </TableCell>
                                    <TableCell>
                                        <IconButton
                                            variant="outlined"
                                            color='primary'
                                            onClick={() => handleOpenCommentPopup(post.postId)}
                                        >
                                            <ChatIcon />
                                        </IconButton>
                                        <TableCell  >
                                            {post.commentCount}
                                        </TableCell></TableCell>
                                    <TableCell>
                                        <IconButton
                                            aria-label="more"
                                            aria-controls="long-menu"
                                            aria-haspopup="true"
                                            disabled={post.status == "reported" ? false : true}
                                            onClick={(e) => handleClick(e, post)}
                                        >
                                            <EditIcon />
                                        </IconButton>
                                    </TableCell>

                                </TableRow>

                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Dialog BackdropProps={{ style: { backgroundColor: 'rgba(128, 128, 128, 0.5)' } }}
                    open={isModalOpen}
                    onClose={handleCloseModal}
                    contentLabel="Full Prompt"
                    style={{
                        content: {
                            top: '50%',
                            left: '50%',
                            right: 'auto',
                            bottom: 'auto',
                            marginRight: '-50%',
                            transform: 'translate(-50%, -50%)',
                        },
                    }}
                >                     <DialogContent>

                        <h2>Full {selectedPropertyType}  </h2>
                        <div>   {selectedPropertyType == "prompt" ? selectedPost?.prompt : selectedPropertyType == "title" ? selectedPost?.title : selectedPost?.failurelog}</div>
                    </DialogContent>
                </Dialog>
                <Dialog BackdropProps={{ style: { backgroundColor: 'rgba(128, 128, 128, 0.5)' } }} open={isOpenMenu} onClose={handleClosePopup}>
                    <DialogTitle>Change Post Status</DialogTitle>
                    <DialogContent>
                        <Formik
                            initialValues={{
                                status: "",
                                adjudicationReason: "",
                            }}
                            onSubmit={async (values) => {
                                handleStatusMenuItemClick(values);
                            }}
                        >
                            {({ handleSubmit, handleBlur, handleChange, values }) => (
                                <Form onSubmit={handleSubmit}>
                                    <Grid container style={{ marginBottom: '5px', marginTop: '5px' }}>
                                        <Grid item xs={12}  >

                                            <RadioGroup value={values.status} name="status" onChange={handleChange} onBlur={handleBlur}>
                                                <FormControlLabel value="completed" control={<Radio />} label="Completed" />
                                                <FormControlLabel value="banned" control={<Radio />} label="Banned" />
                                            </RadioGroup> </Grid>
                                        <Grid item xs={12}  >
                                            <FormControl variant="outlined" fullWidth>
                                                <InputLabel htmlFor="adjudicationReason-reason-label">Reason</InputLabel>
                                                <Select
                                                    label="Reason"
                                                    value={values.adjudicationReason}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    inputProps={{ name: 'adjudicationReason', id: 'adjudicationReason-reason-label' }}
                                                >
                                                    {values.status === "banned" ? (
                                                        <>
                                                            <MenuItem value="Frauds and scams">Frauds and scams</MenuItem>
                                                            <MenuItem value="Dangerous activities">Dangerous activities</MenuItem>
                                                            <MenuItem value="Misinformation">Misinformation</MenuItem>
                                                            <MenuItem value="Nudity and sexual content">Nudity and sexual content</MenuItem>
                                                            <MenuItem value="Hate and harassment">Hate and harassment</MenuItem>
                                                            <MenuItem value="Intellectual property">Intellectual property</MenuItem>
                                                            <MenuItem value="Suicide and self-harm">Suicide and self-harm</MenuItem>
                                                            <MenuItem value="Violence, abuse and criminal exploitation">Violence, abuse and criminal exploitation</MenuItem>
                                                        </>
                                                    ) : (
                                                        <MenuItem value="No violation">No violation</MenuItem>
                                                    )}
                                                    <MenuItem value="Other">Other</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} margin={2}>
                                            <Button type="submit" variant="contained" color="primary" fullWidth  >
                                                <Check />
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Form>
                            )}
                        </Formik>
                    </DialogContent>
                </Dialog>
                <div className="pagination">
                    <Pagination
                        count={totalPages}
                        page={currentPage}
                        onChange={handlePageChange}
                        variant="outlined" shape="rounded"
                        color="primary" />
                </div>
                <Dialog open={isCommentOpen} onClose={handleClosePopup}>
                    <DialogContent style={{ minWidth: '300px', overflowX: 'auto' }}>
                        <Typography variant="h5" align='center'>List of comments :</Typography>
                        <List ref={commentsContainerRef} style={{ maxHeight: '300px', overflowY: 'auto' }}>
                            {comments.map((comment) => (
                                <ListItem key={comment.createdAt}>
                                    <ListItemAvatar>
                                        <Avatar>{comment.avatarUrl}</Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary={comment.comment} secondary={` ${comment.displayName}`} />
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <Typography variant="body2" className={getStatusClass(comment.status)}>
                                            {comment.status.charAt(0).toUpperCase() + comment.status.slice(1)}
                                        </Typography>
                                        <IconButton
                                            aria-label="Edit status"
                                            onClick={(event) => handleMenuOpen(event, comment)}
                                            size="small"
                                            color='primary'
                                            style={{ marginLeft: '20px' }}
                                        >
                                            <EditIcon fontSize="small" />
                                        </IconButton>
                                    </div>
                                    <Menu
                                        id="status-menu"
                                        anchorEl={anchorEl}
                                        open={Boolean(anchorEl && selectedComment && selectedComment.id === comment.id)}
                                        onClose={handleMenuClose}
                                    >
                                        <DialogContent>
                                            <Formik
                                                initialValues={{
                                                    status: "",
                                                    adjudicationReason: "",
                                                }}
                                                onSubmit={async (values) => {
                                                    handleCommentStatusMenuItemClick(values);
                                                }}
                                            >
                                                {({ handleSubmit, handleBlur, handleChange, values }) => (
                                                    <Form onSubmit={handleSubmit}>
                                                        <Grid container style={{ marginBottom: '5px', marginTop: '5px' }}>
                                                            <Grid item xs={12}  >

                                                                <RadioGroup value={values.status} name="status" onChange={handleChange} onBlur={handleBlur}>
                                                                    <FormControlLabel value="active" control={<Radio />} label="Active" />
                                                                    <FormControlLabel value="banned" control={<Radio />} label="Banned" />
                                                                </RadioGroup> </Grid>
                                                            <Grid item xs={12}  >
                                                                <FormControl variant="outlined" fullWidth>
                                                                    <InputLabel htmlFor="adjudicationReason-reason-label">Reason</InputLabel>
                                                                    <Select
                                                                        label="Reason"
                                                                        value={values.adjudicationReason}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        inputProps={{ name: 'adjudicationReason', id: 'adjudicationReason-reason-label' }}
                                                                    >
                                                                        {values.status === "banned" ? (
                                                                            [
                                                                                 <MenuItem value="Frauds and scams">Frauds and scams</MenuItem>,
                                                                                <MenuItem value="Dangerous activities">Dangerous activities</MenuItem>,
                                                                                <MenuItem value="Misinformation">Misinformation</MenuItem>,
                                                                                <MenuItem value="Nudity and sexual content">Nudity and sexual content</MenuItem>,
                                                                                <MenuItem value="Hate and harassment">Hate and harassment</MenuItem>,
                                                                                <MenuItem value="Intellectual property">Intellectual property</MenuItem>,
                                                                                <MenuItem value="Suicide and self-harm">Suicide and self-harm</MenuItem>,
                                                                                <MenuItem value="Violence, abuse and criminal exploitation">Violence, abuse and criminal exploitation</MenuItem>
                                                                        ] ) : (
                                                                            <MenuItem value="No violation">No violation</MenuItem>
                                                                        )}
                                                                        <MenuItem value="Other">Other</MenuItem>
                                                                    </Select>
                                                                </FormControl>
                                                            </Grid>
                                                            <Grid item xs={12} sm={12} md={12} margin={2}>
                                                                <Button type="submit" variant="contained" color="primary" fullWidth  >
                                                                    <Check />
                                                                </Button>
                                                            </Grid>
                                                        </Grid>
                                                    </Form>
                                                )}
                                            </Formik>
                                        </DialogContent>
                                    </Menu>

                                </ListItem>
                            ))}
                            {hasMore && (
                                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                                    <CircularProgress />
                                </div>
                            )}    {comments.length == 0 && !hasMore && (
                                <Typography>No content yet.</Typography>
                            )}
                        </List>
                    </DialogContent>
                </Dialog>

            </div>}
        </div>
    );
};

const useStyles = makeStyles({ "name": { Posts } })(theme => ({
    formInput: {
        ...theme.typography.customInput,
        marginBottom: '10px !important'
    },
    avatar: {
        margin: 'auto',
        width: '100px!important',
        height: '100px!important'
    },
    dialogHeader: {
        paddingBottom: '50px!important'
    },
    disabled: {
        color: theme.palette.error.main, // Customize the color for disabled status
    },
    active: {
        color: theme.palette.primary.main, // Customize the color for active status
    },
    completed: {
        color: theme.palette.primary.main, // Customize the color for active status
    },
    reported: {
        color: theme.palette.error.main, // Customize the color for deleted status
    },
    banned: {
        color: theme.palette.error.main, // Customize the color for deleted status
    },
    deleted: {
        color: theme.palette.error.main, // Customize the color for deleted status
    }, barMenuItem: {
        width: '200px',
        textAlign: 'center',
        padding: '20px!important',
        "&:hover": {
            background: "rgb(195 239 243)"
        }
    },
}));

export default Posts;
