import { Box } from '@mui/material';
import React from 'react' 
import Comments from '../../../components/Views/comments/comments';

const CommentsPage = () => {
    return (
        <Box m={6}>
            <Comments />
        </Box>
    )
}

export default CommentsPage;