import React, { useState, useEffect, useRef } from 'react';
import { getFunctions, httpsCallable } from 'firebase/functions';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import {
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Paper,
    MenuItem,
    IconButton,
    Dialog,
    DialogContent,
    Pagination,
    DialogTitle,
    Typography,
    CircularProgress,
    List,
    ListItem,
    ListItemText,
    ListItemAvatar,
    Avatar,
    Menu,
    Grid,
    Modal,
    Tooltip,
    Button,
    Box,
    Container, TextField,
    InputLabel,
    FormControl,
    Select,
    RadioGroup,
    FormControlLabel,
    Radio
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import Loader from '../../../ui-elements/loader';
import ChatIcon from '@mui/icons-material/Chat';
import EditIcon from '@mui/icons-material/Edit';
import CopyToClipboard from 'react-copy-to-clipboard';
import { BsClipboard, BsEye, BsSearch } from 'react-icons/bs';
import { toast } from 'react-toastify';
import { Field, Form, Formik } from 'formik';
import { Check, Loop, Refresh } from '@mui/icons-material';

const Comments = ({ ...props }) => {

    const [comments, setComments] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const { classes } = useStyles();
    const [isOpen, setIsOpen] = useState(false);
    const [selectedPropertyType, setSelectedPropertyType] = useState(null);

    const [isOpenMenu, setIsOpenMenu] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const commentsContainerRef = useRef(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedComment, setSelectedComment] = useState(null);
    const [isViewPostOpen, setIsViewPostOpen] = useState(false);
    const [post, setPost] = useState({});

    const [postLoading, setPostLoading] = useState(false);
    const formRef = useRef();
    const [searchValues, setSearchValues] = useState({});

    const functions = getFunctions();
    const [loading, setLoading] = useState(false);

    const pageSize = 10; // Number of posts per page
    const [pageTokens, setPageTokens] = useState([null]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const MAX_LENGTH = 30;
    const [commentsCount, setCommentsCount] = useState(0);


    const getAllCount = async () => {
        const functions = getFunctions();

        const callableReturnMessage = httpsCallable(functions, 'getAllCounts');

        callableReturnMessage().then((result) => {
            setCommentsCount(result.data.commentsCount)

        }).catch((error) => {
            console.log(error);
        });

    };

    useEffect(() => {
        getAllCount();
    }, []);
    const handleOpenModal = (comment, type) => {
        setSelectedComment(comment);
        setSelectedPropertyType(type);
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };
    const handleMenuOpen = (event, comment) => {
        setSelectedComment(comment);

        setAnchorEl(event.currentTarget);

    };


    const handleViewPostClick = async (comment, type) => {
        setSelectedComment(comment);
        setIsViewPostOpen(true);
        setPost(null);

        console.log('comment', comment)
        try {
            setPostLoading(true);

            const callableReturnMessage = httpsCallable(functions, 'getPostByIdForAdmin');
            const response = await callableReturnMessage({ "postId": comment.postId });
            console.log('response', response.data)

            setPost(response.data);

            setPostLoading(false);

        } catch (error) {
            setPostLoading(false);
            console.log(error);
        }
    }
    const handleCloseViewPostClick = () => {
        setPost(null);
        setIsViewPostOpen(false);

        setSelectedComment(null);
    }
    const handleMenuClose = () => {
        setAnchorEl(null);
        setSelectedComment(null);
    };
    const fetchComments = async (page) => {
        try {
            setCurrentPage(page);

            setLoading(true);

            const callableReturnMessage = httpsCallable(functions, 'getPaginatedComments');
            const pageToken = pageTokens[page - 1];
            const response = await callableReturnMessage({ pageSize, pageToken });
            setComments(response.data.comments);
            setTotalPages(Math.ceil(response.data.total / pageSize));
            // Update pageTokens state to keep track of page tokens for each page
            setPageTokens((prevTokens) => {
                const updatedTokens = [...prevTokens];
                updatedTokens[page] = response.data.nextPageToken;
                return updatedTokens;
            });
            setLoading(false);

        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    };
    useEffect(() => {
        setLoading(true);

        fetchComments(1);
    }, []);



    const handleOpenPopup = (comment) => {

        setSelectedComment(comment);
        console.log('handleClick', selectedComment.title)
        setIsOpen(true);

    };

    const handleClosePopup = () => {
        setIsOpen(false);
        setIsOpenMenu(false);
    };
    const getStatusClass = (status) => {
        switch (status) {
            case 'disabled':
                return classes.disabled;
            case 'active':
                return classes.active;
            case 'completed':
                return classes.active;
            case 'deleted':
                return classes.deleted;
            case 'failed':
                return classes.deleted;
            case 'reported':
                return classes.reported;
            case 'banned':
                return classes.reported;
            default:
                return '';
        }
    };
    const handleClick = (event, comment) => {
        setSelectedComment(comment);

        setIsOpenMenu(true);
    };

    const handleClose = () => {
        setSelectedComment(null);
        setIsOpenMenu(false);
        setIsOpen(false);


    };

    const handleStatusMenuItemClick = (status) => {
        handleCommentStatusChange(status);
        handleClose();
    };
    const handleCommentStatusChange = (values) => {
        console.log('selectedComment', selectedComment);
        const callableReturnMessage = httpsCallable(functions, 'changeCommentStatusByAdmin');
        selectedComment.status = values.status;

        callableReturnMessage({ commentId: selectedComment.commentId, postId: selectedComment.postId, status: values.status, adjudicationReason: values.adjudicationReason }).then(() => {
            toast.success('Status was changed successfully.');

        }).catch((error) => {
            console.log(error);
            toast.success("Couldn/'t change status.");

        });
    }
    const renderContent = (comment) => {
        if (comment?.comment?.length > MAX_LENGTH) {
            return (
                <>
                    {comment.comment.substring(0, MAX_LENGTH)}...
                    <span onClick={() => handleOpenModal(comment, 'comment')} style={{ color: 'blue', cursor: 'pointer' }}> 🔍</span >
                </>
            );
        }
        return comment?.comment;
    };
    const renderLogContent = (comment) => {
        if (comment?.failurelog?.length > MAX_LENGTH) {
            return (
                <>
                    {comment.failurelog.substring(0, 20)}...
                    <span onClick={() => handleOpenModal(comment, 'failure log')} style={{ color: 'blue', cursor: 'pointer' }}> 🔍</span >
                </>
            );
        }
        return comment?.failurelog;
    };
    const renderTitleContent = (comment) => {
        if (comment?.title?.length > MAX_LENGTH) {
            return (
                <>
                    {comment.title.substring(0, 20)}...
                    <span onClick={() => handleOpenModal(comment, 'title')} style={{ color: 'blue', cursor: 'pointer' }}> 🔍</span >
                </>
            );
        }
        return comment?.title;
    };

    const handleCommentStatusMenuItemClick = (values) => {
        console.log('status', values)
        handleCommentStatusChange(values);
        handleMenuClose();
        handleClosePopup();

    };
    const formatDate = (timestamp) => {
        if (!timestamp) {
            return "Invalid Date";
        }

        // Construct a JavaScript Date object using seconds and optional nanoseconds
        const date = new Date(timestamp._seconds * 1000); // Convert seconds to milliseconds

        // Format the date as needed
        const formattedDate = date.toLocaleString("en-US", {
            timeZone: "UTC", // Adjust timezone as per your requirement
            year: "numeric",
            month: "short",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            second: "numeric",
        });
        return formattedDate;

    };
    const searchBy = (values, page) => {
        console.log('searchComments', values)
        setCurrentPage(page);

        setLoading(true);

        const searchComments = httpsCallable(functions, 'searchComments');
        const pageToken = pageTokens[page - 1];

        searchComments({

            userId: values.userId,
            postId: values.postId,
            comment: values.comment,
            commentId: values.commentId,

            userDisplayName: values.userDisplayName,
            sortOrder: values.orderby,
            status: values.status,
            pageSize: 10,
            pageToken: pageToken
        }).then((response) => {
            console.log('response', response.data);
            setComments(response.data.comments);
            setTotalPages(Math.ceil(response.data.total / pageSize));
            // Update pageTokens state to keep track of page tokens for each page
            setPageTokens((prevTokens) => {
                const updatedTokens = [...prevTokens];
                updatedTokens[page] = response.data.nextPageToken;
                return updatedTokens;
            });
            setLoading(false);

            console.log('response.date', response.data)

        }).catch((error) => {
            console.error("Error searching posts:", error);
        });

    }
    const handlePageChange = (event, page) => {

        // Check if search form is not empty
        console.log('formRef', formRef)
        if (formRef && formRef.current && (formRef.current.values.userId != "" ||
            formRef.current.values.postId != "" ||
            formRef.current.values.userDisplayName != "" ||
            formRef.current.values.postTitle != "" ||
            formRef.current.values.status != "")

        ) {
            searchBy(searchValues, page);
        } else {
            console.log('fetchPosts', page)

            // Handle pagination without search
            fetchComments(page);
        }
    };
    const [showFilters, setShowFilters] = useState(false);

    const toggleFilters = () => {
        setShowFilters(prev => !prev);
    };
    return (
        <div>
            <Grid container style={{ marginBottom: '20px', backgroundColor: 'white', padding: "20px" }} >
                <Grid item xs={12} sm={12} md={2}>
                    <Typography color="primary" variant="h4">Comments Counts : {commentsCount}</Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={10}>
                    <Button variant="outlined" onClick={toggleFilters} style={{ display: 'flex', marginLeft: 'auto' }}>
                        {showFilters ? 'Hide Filters' : 'Show Filters'}
                    </Button>

                </Grid>
                <Grid item xs={12} sm={12} md={12}>


                    {showFilters && (<Formik
                        innerRef={formRef}

                        initialValues={{
                            userId: "",
                            postId: "",
                            commentId: "",
                            userDisplayName: "",
                            comment: "",
                            orderby: "desc",
                            status: ""
                        }}
                        onSubmit={async (
                            values,
                        ) => {
                            searchBy(values, 1);

                        }}
                    >
                        {({ handleSubmit, handleBlur, values }) => (

                            <Form onSubmit={handleSubmit} >
                                <Grid container style={{ marginBottom: '5px', marginTop: '5px', }} >

                                    <Grid item xs={12} sm={12} md={3} margin={2}>
                                        <Field
                                            name="userId"
                                            as={TextField}
                                            label="User ID"
                                            variant="outlined"
                                            onBlur={handleBlur}
                                            value={values.userId}

                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={3} margin={2}>
                                        <Field
                                            name="postId"
                                            as={TextField}
                                            label="Post ID"
                                            variant="outlined"
                                            onBlur={handleBlur}
                                            value={values.postId}

                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={3} margin={2}>
                                        <Field
                                            name="commentId"
                                            as={TextField}
                                            label="Comment ID"
                                            variant="outlined"
                                            onBlur={handleBlur}
                                            value={values.commentId}

                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={3} margin={2}>
                                        <Field
                                            name="userDisplayName"
                                            as={TextField}
                                            label="User Display Name"
                                            variant="outlined"
                                            onBlur={handleBlur}
                                            value={values.userDisplayName}

                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={3} margin={2}>
                                        <Field
                                            name="comment"
                                            as={TextField}
                                            label="Comment"
                                            variant="outlined"
                                            onBlur={handleBlur}
                                            value={values.comment}

                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={3} margin={2}>
                                        <FormControl fullWidth variant="outlined">
                                            <InputLabel id="sort-order-label">Sort Order</InputLabel>
                                            <Field
                                                name="orderby"
                                                as={Select}
                                                labelId="sort-order-label"
                                                label="Sort Order"
                                                variant="outlined"
                                                value={values.orderby}

                                            >
                                                <MenuItem value="asc">Ascending</MenuItem>
                                                <MenuItem value="desc">Descending</MenuItem>
                                            </Field>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={3} margin={2}>
                                        <FormControl fullWidth variant="outlined">
                                            <InputLabel id="status-order-label">Status</InputLabel>
                                            <Field
                                                name="status"
                                                as={Select}
                                                labelId="status-order-label"
                                                label="Status"
                                                variant="outlined"
                                                value={values.status}

                                            >
                                                <MenuItem value="active">Active</MenuItem>
                                                <MenuItem value="reported">Reported</MenuItem>
                                                <MenuItem value="deleted">Deleted</MenuItem>
                                                <MenuItem value="banned">Banned</MenuItem>

                                            </Field>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={3} margin={2}>
                                        <Button type="submit" variant="contained" color="primary"  >
                                            <BsSearch />
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </Formik>)}
                </Grid>
            </Grid>
            {loading ? <Loader /> : <div>

                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>ID</TableCell>
                                <TableCell>Comment</TableCell>
                                <TableCell>User</TableCell>


                                <TableCell>Status</TableCell>
                                <TableCell> Creation Date</TableCell>

                                <TableCell> Post</TableCell>

                                <TableCell>
                                    <IconButton
                                        aria-label="see"
                                        aria-controls="long-menu"
                                        aria-haspopup="true"
                                        onClick={(e) => fetchComments(1)}
                                    >
                                        <Refresh />
                                    </IconButton>


                                </TableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>

                            {comments.map((comment) => (
                                <TableRow key={comment.commentId}>
                                    <TableCell>
                                        <CopyToClipboard text={comment.commentId}>

                                            <Button variant="text" startIcon={<BsClipboard />} style={{ padding: '0' }} >
                                                <Tooltip title="Comment ID" placement='right' arrow>
                                                    <div>
                                                        {comment.commentId.substring(0, 2)}...{comment.commentId.substring(comment.commentId.length - 1, comment.commentId.length)}
                                                    </div>
                                                </Tooltip>
                                            </Button>
                                        </CopyToClipboard></TableCell>
                                    <TableCell>{renderContent(comment)}</TableCell>

                                    <TableCell>
                                        <Zoom>
                                            {comment.avatarUrl && (
                                                <img src={comment.avatarUrl} width={30} height={30} alt="user" style={{ cursor: 'zoom-in', borderRadius: '50%', marginTop: '10px' }} />
                                            )}
                                        </Zoom>
                                        <div>
                                            {comment.displayName}</div>
                                        <CopyToClipboard text={comment.userId}>
                                            <Button variant="text" startIcon={<BsClipboard />} style={{ padding: '0' }} >
                                                <Tooltip title="User ID" placement='right' arrow>
                                                    <div>
                                                        {comment.userId.substring(0, 2)}...{comment.userId.substring(comment.userId.length - 1, comment.userId.length)}
                                                    </div>
                                                </Tooltip>
                                            </Button>
                                        </CopyToClipboard>
                                    </TableCell>

                                    <TableCell className={getStatusClass(comment.status)}>
                                        {comment.status ? comment.status.charAt(0).toUpperCase() + comment.status.slice(1) : comment.status} </TableCell>
                                    <TableCell>
                                        {formatDate(comment.createdAt)}
                                    </TableCell>
                                    <TableCell>
                                        <IconButton
                                            aria-label="see"
                                            aria-controls="long-menu"
                                            aria-haspopup="true"
                                            onClick={(e) => handleViewPostClick(comment)}
                                        >
                                            <BsEye />
                                        </IconButton>
                                    </TableCell>



                                    <TableCell>
                                        <IconButton
                                            aria-label="more"
                                            aria-controls="long-menu"
                                            aria-haspopup="true"
                                            onClick={(event) => handleMenuOpen(event, comment)}
                                            disabled={comment.status == 'reported' ? false : true}
                                        >
                                            <EditIcon />
                                        </IconButton>
                                        <Menu
                                            id="status-menu"
                                            anchorEl={anchorEl}
                                            open={Boolean(anchorEl && selectedComment && selectedComment.id === comment.id)}
                                            onClose={handleMenuClose}
                                        >
                                            <DialogContent>
                                                <Formik
                                                    initialValues={{
                                                        status: "",
                                                        adjudicationReason: "",
                                                    }}
                                                    onSubmit={async (values) => {
                                                        handleCommentStatusMenuItemClick(values);
                                                    }}
                                                >
                                                    {({ handleSubmit, handleBlur, handleChange, values }) => (
                                                        <Form onSubmit={handleSubmit}>
                                                            <Grid container style={{ marginBottom: '5px', marginTop: '5px' }}>
                                                                <Grid item xs={12}  >

                                                                    <RadioGroup value={values.status} name="status" onChange={handleChange} onBlur={handleBlur}>
                                                                        <FormControlLabel value="active" control={<Radio />} label="Active" />
                                                                        <FormControlLabel value="banned" control={<Radio />} label="Banned" />
                                                                    </RadioGroup> </Grid>
                                                                <Grid item xs={12}  >
                                                                    <FormControl variant="outlined" fullWidth>
                                                                        <InputLabel htmlFor="adjudicationReason-reason-label">Reason</InputLabel>
                                                                        <Select
                                                                            label="Reason"
                                                                            value={values.adjudicationReason}
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            inputProps={{ name: 'adjudicationReason', id: 'adjudicationReason-reason-label' }}
                                                                        >
                                                                            {values.status === "banned" ? (
                                                                                [
                                                                                    <MenuItem value="Frauds and scams">Frauds and scams</MenuItem>,
                                                                                    <MenuItem value="Dangerous activities">Dangerous activities</MenuItem>,
                                                                                    <MenuItem value="Misinformation">Misinformation</MenuItem>,
                                                                                    <MenuItem value="Nudity and sexual content">Nudity and sexual content</MenuItem>,
                                                                                    <MenuItem value="Hate and harassment">Hate and harassment</MenuItem>,
                                                                                    <MenuItem value="Intellectual property">Intellectual property</MenuItem>,
                                                                                    <MenuItem value="Suicide and self-harm">Suicide and self-harm</MenuItem>,
                                                                                    <MenuItem value="Violence, abuse and criminal exploitation">Violence, abuse and criminal exploitation</MenuItem>
                                                                                ]) : (
                                                                                <MenuItem value="No violation">No violation</MenuItem>
                                                                            )}
                                                                            <MenuItem value="Other">Other</MenuItem>
                                                                        </Select>
                                                                    </FormControl>
                                                                </Grid>
                                                                <Grid item xs={12} sm={12} md={12} margin={2}>
                                                                    <Button type="submit" variant="contained" color="primary" fullWidth  >
                                                                        <Check />
                                                                    </Button>
                                                                </Grid>
                                                            </Grid>
                                                        </Form>
                                                    )}
                                                </Formik>
                                            </DialogContent>
                                        </Menu>

                                    </TableCell>

                                </TableRow>

                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Dialog BackdropProps={{ style: { backgroundColor: 'rgba(128, 128, 128, 0.5)' } }}
                    open={isModalOpen}
                    onClose={handleCloseModal}
                    contentLabel="Full Prompt"
                    style={{
                        content: {
                            top: '50%',
                            left: '50%',
                            right: 'auto',
                            bottom: 'auto',
                            marginRight: '-50%',
                            transform: 'translate(-50%, -50%)',
                        },
                    }}
                >                     <DialogContent>

                        <h2>Full Comment  </h2>
                        <div>   {selectedComment?.comment}</div>

                    </DialogContent>
                </Dialog>


                <Dialog BackdropProps={{ style: { backgroundColor: 'rgba(128, 128, 128, 0.5)' } }} open={isViewPostOpen} onClose={handleCloseViewPostClick}>
                    <DialogTitle textAlign="center">View Post</DialogTitle>
                    <DialogContent>
                        {postLoading ? <CircularProgress /> : <Container>
                            <img src={post?.mediaUrl} style={{ width: "50%" }} className='AlignCenter' />
                            <Typography variant="h3" textAlign="center">{post?.title}</Typography>
                            <div className='AlignCenter' >
                                <Typography textAlign="center"> {post?.displayName}</Typography>
                                <CopyToClipboard text={post?.postId}>
                                    <Button variant="text" startIcon={<BsClipboard />} style={{ padding: '0' }}  >
                                        <Tooltip title="Post ID" placement='right' arrow>
                                            <div>
                                                {post?.postId?.substring(0, 2)}...{post?.postId?.substring(post?.postId?.length - 1, post?.postId?.length)}
                                            </div>
                                        </Tooltip>
                                    </Button>
                                </CopyToClipboard></div>
                        </Container>}
                    </DialogContent>
                </Dialog>
                <div className="pagination">
                    <Pagination
                        count={totalPages}
                        page={currentPage}
                        onChange={handlePageChange}
                        variant="outlined" shape="rounded"
                        color="primary" />
                </div>

            </div>}
        </div>
    );
};

const useStyles = makeStyles({ "name": { Comments } })(theme => ({
    formInput: {
        ...theme.typography.customInput,
        marginBottom: '10px !important'
    },
    avatar: {
        margin: 'auto',
        width: '100px!important',
        height: '100px!important'
    },
    dialogHeader: {
        paddingBottom: '50px!important'
    },
    disabled: {
        color: theme.palette.error.main, // Customize the color for disabled status
    },
    active: {
        color: theme.palette.primary.main, // Customize the color for active status
    },
    completed: {
        color: theme.palette.primary.main, // Customize the color for active status
    },
    reported: {
        color: theme.palette.error.main, // Customize the color for deleted status
    },
    deleted: {
        color: theme.palette.error.main, // Customize the color for deleted status
    }, barMenuItem: {
        width: '200px',
        textAlign: 'center',
        padding: '20px!important',
        "&:hover": {
            background: theme.palette.success.main
        }
    },
}));

export default Comments;
